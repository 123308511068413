import React from "react"

const SlideContent = props => (
  <>
    <div
      data-sal="slide-up"
      data-sal-delay={props.delay}
      data-sal-easing="ease-out-bounce"
      data-sal-duration="700"
      style={props.style}
      className={props.className}
    >
      {props.children}
    </div>
  </>
)

export default SlideContent
