import { Link } from "gatsby"
import React from "react"
import Slide from "../../components/utility/slide_content"
import CheckLinkDomain from "../utility/current_domain"
import RightArrowPink from "./../../assets/svgs/navigation/Path.svg"
const Links = props => {
  const linkName = props.items.linkName
  const link = props.items.slug
  const icon = props.items.icon

  return link.map((el, idx) => {
    return props.iconOnly && icon[idx].url && icon[idx].url.length > 0 ? (
      <div className={props.className}>
        <Link
          to={el}
          rel="noopener noreferrer"
          target="_blank"
          className="d-flex align-items-center link"
        >
          <img
            src={icon[idx].url}
            alt="social"
            width="16"
            height="16"
            className="social-icon"
          />
          {/* <SVGIcon
            name={`social/${linkName[idx]}`}
            width="16"
            height="16"
            className="social-icon"
          /> */}
          {/* {typeof Namekey !== "undefined" && <Namekey width="16"
              height="16"
              className="social-icon" />} */}
          {props.name && <p className="social-link-text">{linkName[idx]}</p>}
        </Link>
      </div>
    ) : (
      <>
        {props.slide ? (
          <Slide>
            <Link
              className={`${
                linkName[idx] === "Careers"
                  ? linkName[idx].toLowerCase() + " tag"
                  : ""
              }`}
              to={el}
              target={CheckLinkDomain(el) ? "_self" : "_blank"}
            >
              {linkName[idx]}
            </Link>
          </Slide>
        ) : (
          <Link
            className={`${
              linkName[idx] === "Careers"
                ? linkName[idx].toLowerCase() + " tag"
                : ""
            }`}
            to={el}
            target={CheckLinkDomain(el) ? "_self" : "_blank"}
          >
            {linkName[idx]}
            {props.rightArrow && (
              <span>
                <RightArrowPink className="r-arrow-pink" />
              </span>
            )}
          </Link>
        )}
      </>
    )
  })
}

export default Links
